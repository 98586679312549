var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "other-info-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "组织简介" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容" } },
                    [
                      _c("v-ueditor", {
                        model: {
                          value: _vm.form.introduce,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "introduce", $$v)
                          },
                          expression: "form.introduce",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }