<!--
 * @Description: 主要功能
 * @Author: 小广
 * @Date: 2019-08-13 13:32:57
 * @LastEditors: 蒙川
 * @LastEditTime: 2019-10-31 18:11:31
 -->
<template>
  <div class="other-info-wrapper">
    <form-panel
      ref="form"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
    >
      <col2-detail>
        <col2-block title="组织简介">
          <el-form-item label="内容">
            <v-ueditor v-model="form.introduce"></v-ueditor>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import {
  addOrgURL
} from './api'
import { vUeditor } from 'components/control/index'
import {
  Col2Detail,
  Col2Block
} from 'components/bussiness'

export default {
  name: 'otherInfo',
  components: {
    Col2Detail,
    Col2Block,
    vUeditor
  },
  props: {
    data: Object,
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      width: 182,
      form: {
        centerType: 1,
        orgId: 0,
        isTop: undefined,
        topParentId: 0,
        topParentName: '',
        parentId: 0,
        parentName: '',
        orgName: '',
        orgLogo: '',
        status: undefined,
        managerId: '',
        managerName: '',
        tagIds: [],
        communityIds: [],
        houseIds: [],
        tagsAtts: [],
        introduce: ''
      },
      submitConfig: {
        submitUrl: addOrgURL,
        submitMethod: 'POST'
      }
    }
  },
  created () {
    if (this.isEdit) {
      this.submitConfig.submitMethod = 'put'
      this.bindOrgData(this.data)
    }
  },
  methods: {
    acceptBaseInfo (d) {
      console.log(d)
      this.form.isTop = d.isTop
      this.form.orgName = d.orgName
      this.form.orgLogo = d.orgLogo
      this.form.topParentId = d.topParentId
      this.form.topParentName = d.topParentName
      this.form.parentId = d.parentId
      this.form.parentName = d.parentName
      this.form.managerId = d.managerId
      this.form.managerName = d.managerName
      this.form.status = d.status
      this.form.tagsAtts = d.tagsAtts
    },
    bindOrgData (d) {
      this.acceptBaseInfo(d)
      this.form.orgId = d.id
      if (d.tags && d.tags.length > 0) {
        d.tags.map(item => {
          item.name = `${item.oneTagName}-${item.twoTagName}`
        })
      }
      if (d.tagsAttrs && d.tagsAttrs.length > 0) {
        d.tagsAttrs.forEach(item => {
          if (item.inputType === 2 || item.inputType === 3) {
            let temp = []
            item.attributeValue = item.attributeValue !== '' ? JSON.parse(item.attributeValue) : []
            if (item.attributeValue.length > 0) {
              if (item.inputType === 2) {
                item.attributeValue.map(k => {
                  temp.push({
                    url: k
                  })
                })
              } else {
                // 当标签属性为附件时提取文件名称
                item.attributeValue.map(k => {
                  temp.push({
                    url: k,
                    isOther: true,
                    name: k.split('/').pop()
                  })
                })
              }
            }
            item.attributeValue = temp
          }
        })
      }
      this.form.tagsAtts = d.tagsAttrs
      this.form.tagIds = d.tags
      this.editInfo = d.editInfo
      this.editIfAudit = d.editIfAudit
      this.exist = d.exist
      this.firstCommunityId = d.firstCommunityId
      this.otherCommunityIds = d.otherCommunityIds
      this.otherCommunityNames = d.otherCommunityNames
      this.otherHouseIds = d.otherHouseIds
      this.form.houseIds = d.houses.map(item => item.houseId)
      this.form.houseIds.concat(this.otherHouseIds)
      this.form.communityIds = d.communities.map(item => item.communityId).concat(this.otherCommunityIds)
      this.form.introduce = d.introduce
    },

    async submitBefore (data) {
      console.log(data)
      if (data.tagsAtts) {
        data.tagsAtts.forEach(item => {
          let temp = []
          if (item.inputType === 2 || item.inputType === 3) {
            // 处理标签属性图片和文件为空时的问题，后台未处理 attributeValue = [] 的情况
            if (item.attributeValue.length === 0) {
              item.attributeValue = ''
            } else {
              // 把文件对象改成url数组
              item.attributeValue.forEach(k => {
                temp.push(k.url)
              })
              item.attributeValue = temp
            }
          }
        })
      }
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
.other-info-wrapper {
  .house-warning-wrapper {
    display: flex;
    margin-bottom: 10px;

    .house-name-text {
      margin-right: 10px;
      width: 300px;
    }

    .warning-time-wrapper {
      .time-wrapper {
          display: flex !important;
          width: 550px
      }

      .warning-tip-text {
        margin-left: 105px;
        color: #AAAAAA;
        font-size: 10px;
        height: 20px;
      }

      .warning-content-wrapper {
          display: flex;
          margin-top: 10px;
          margin-left: 105px;

          .warning-wrapper {
            width: 230px;

            // 有预警状态的时候，间隙要小
            .checkbox-bottom-small {
              margin-bottom: -10px;
            }

            // 没有预警状态的时候，间隙要有
            .checkbox-bottom-big {
              margin-bottom: 10px;
            }

            .status-tetx {
              margin-left: 25px;
              color: #AAAAAA;
            }
          }
      }
    }
  }
}
</style>
