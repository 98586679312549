<template>
  <div ref="tabs" class='orgManageForm-container'>
    <tabs-panel
      :tabRouter="false"
      :tabs="tabs"
      :activeLabel="activeLabel"
      :before="getOrgDetail"
      @change="changeTab"
    />
  </div>
</template>
<script>
import { getOrgDetailURL } from './api'
import { TabsPanel } from 'components/bussiness'
import BaseInfo from './BaseInfo'
import OtherInfo from './OtherInfo'

export default {
  name: 'orgManageForm',
  components: {
    TabsPanel
  },
  data () {
    return {
      centerType: 1,
      activeLabel: '基础信息',
      orgInfo: {
        data: {},
        isEdit: false
      },
      tabs: [],
      queryUrl: getOrgDetailURL
    }
  },
  created () {
    let breadcrumb = '新增'
    this.tabs = [
      {
        label: '基础信息',
        component: BaseInfo,
        props: this.orgInfo
      },
      {
        label: '组织简介',
        component: OtherInfo,
        props: this.orgInfo
      }
    ]
    if (this.$route.query.edit) {
      breadcrumb = '编辑'
      this.orgInfo.isEdit = true
    }
    this.$setBreadcrumb(breadcrumb)
  },
  methods: {
    setActiveLabel (activeLabel) {
      this.activeLabel = activeLabel
    },
    changeTab (index) {
      switch (index) {
        case 0: this.activeLabel = '基础信息'; break
        case 1: this.activeLabel = '组织简介'; break
      }
    },
    getOrgDetail (next) {
      let _this = this
      if (_this.orgInfo.isEdit) {
        this.$axios({
          url: getOrgDetailURL,
          data: {
            orgId: this.$route.query.id,
            centerType: this.centerType
          },
          method: 'POST'
        }).then(res => {
          _this.orgInfo.data = res.data
          next()
        })
      } else {
        next()
      }
    }
  }
}
</script>
