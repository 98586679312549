<template>
  <div>
    <form-panel
      ref="baseForm"
      :form="form"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <div v-if="isEdit">
            <el-form-item label="是否顶层组织" :rules="[{ required: true, message: '请选择是否顶层组织', trigger: 'blur' }]">
              <span>{{form.isTop === 1 ? '是' : '否'}}</span>
            </el-form-item>
            <div v-if="form.isTop === 0">
              <el-form-item label="顶层组织">
                <span>{{form.topParentName}}</span>
              </el-form-item>
              <el-form-item label="上级组织">
                <span>{{form.parentName}}</span>
              </el-form-item>
            </div>
            <el-form-item label="组织名称" :rules="[{ required: true, message: '请输入组织名称', trigger: 'blur' }]">
              <span>{{form.orgName}}</span>
            </el-form-item>
            <el-form-item label="组织logo">
              <img width="140" height="140" :src="form.orgLogo" alt="">
            </el-form-item>
            <el-form-item label="组织状态" :rules="[{ required: true, message: '请选择组织状态', trigger: 'blur' }]">
              <span>{{form.status === 1 ? '正常' : '关闭'}}</span>
            </el-form-item>
            <el-form-item label="组织超管">
              <span>{{form.managerName}}</span>
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item label="是否顶层组织" :rules="[{ required: true, message: '请选择是否顶层组织', trigger: 'blur' }]" prop="isTop">
              <v-select v-model="form.isTop" :options="isTopOps" :width="width"></v-select>
            </el-form-item>
            <div v-if="form.isTop === 0">
              <el-form-item label="顶层组织">
                <v-select2
                  v-model="form.topParentId"
                  placeholder="查询顶层组织"
                  v-bind="searchOrgParams"
                  :width="width"
                ></v-select2>
              </el-form-item>
              <el-form-item label="上级组织">
                <v-select2
                  v-model="form.parentId"
                  placeholder="查询上级组织"
                  v-bind="searchOrgParams"
                  :width="width"
                  :subjoin="{topId: form.topParentId}"
                ></v-select2>
              </el-form-item>
            </div>
            <el-form-item label="组织名称" :rules="[{ required: true, message: '请输入组织名称', trigger: 'blur' }]" prop="orgName">
              <v-input v-model="form.orgName" placeholder="请输入组织名称" :width="width"></v-input>
            </el-form-item>
            <el-form-item label="组织logo">
              <v-uploader v-bind="uploadLogoOptions" :fileList.sync="orgLogoList">
                <template #tip>
                  点击上传图片，图片最大不超过5M
                </template>
              </v-uploader>
            </el-form-item>
            <el-form-item label="组织状态" :rules="[{ required: true, message: '请选择组织状态', trigger: 'blur' }]" prop="status">
              <v-select v-model="form.status" :options="statusOps" :width="width"></v-select>
            </el-form-item>
            <el-form-item label="组织超管">
              <v-select2
                v-model="form.managerId"
                placeholder="查询组织超管"
                v-bind="searchManagerParams"
                @onChange="changeManage"
                :width="width"
              ></v-select2>
            </el-form-item>
          </div>
        </col2-block>
      </col2-detail>
      <col2-block title="补充信息" v-if="form.tagsAtts && form.tagsAtts.length">
          <div v-for="(item, index) in form.tagsAtts" :key="`${item.id}&&${item.attributeName}`">
            <div v-if="item.inputType === 1">
              <el-form-item
                :label="item.attributeName"
                :rules="[{required: item.required === 1, message: '请添加' + item.attributeName}]"
                :prop="`tagsAtts.${index}.attributeValue`"
              >
                <v-input
                  v-model="item.attributeValue"
                  :placeholder="`请输入${item.attributeName}`"
                  :width="width">
                 </v-input>
              </el-form-item>
            </div>
            <div v-if="item.inputType === 2">
              <el-form-item
                :label="item.attributeName"
                :rules="[{required: item.required === 1, message: '请添加' + item.attributeName}]"
                :prop="`tagsAtts.${index}.attributeValue`"
              >
                <v-uploader v-bind="uploadImagesOptions" :fileList.sync="item.attributeValue">
                  <template #tip>
                    单张图片的大小不能超过 5M
                  </template>
                </v-uploader>
              </el-form-item>
            </div>
            <div v-if="item.inputType === 3">
              <el-form-item
                :label="item.attributeName"
                :rules="[{required: item.required === 1, message: '请添加' + item.attributeName}]"
                :prop="`tagsAtts.${index}.attributeValue`"
              >
                <v-uploader v-bind="uploadFilesOptions" :fileList.sync="item.attributeValue">
                  <template #tip>
                    单个文件的大小不能超过 10M
                  </template>
                </v-uploader>
              </el-form-item>
            </div>
            <div v-if="item.inputType === 4">
              <el-form-item
                :label="item.attributeName"
                :rules="[{required: item.required === 1, message: '请添加' + item.attributeName}]"
                :prop="`tagsAtts.${index}.attributeValue`"
              >
                <v-select
                  v-model="item.attributeValue"
                  :placeholder="`请输入${item.attributeName}`"
                  :options="item.options"
                  :width="width">
                 </v-select>
              </el-form-item>
            </div>
          </div>
        </col2-block>
      <template #footerSlot>
        <v-button text="下一步" type="success" @click="checkOrg"></v-button>
      </template>
    </form-panel>
  </div>
</template>

<script>
import {
  getOrgListURL,
  getOrgManagerURL,
  uploadFileURL,
  checkOrgURL,
  getSelectOpsURL
} from './api'
import { vUploader } from 'components/control/index'
import { isTopOps, statusOps } from './map'
import { Col2Detail, Col2Block } from 'components/bussiness'

export default {
  name: 'baseInfo',
  components: {
    Col2Detail,
    Col2Block,
    vUploader
  },
  props: {
    data: Object,
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      width: 182,
      form: {
        centerType: 0,
        orgId: 0,
        isTop: 1,
        topParentId: 0,
        topParentName: '',
        parentId: 0,
        parentName: '',
        orgLogo: '',
        orgName: '',
        managerId: '',
        managerName: '',
        status: 1,
        tagsAtts: []
      },
      // 用于判断是否有改过 是否顶层组织、组织名称、顶层组织、上级组织 数据，如果改过就校验组织，否则不校验组织
      orgCheckData: {
        isTop: '',
        orgName: '',
        topOrgId: '',
        supOrgId: ''
      },
      editIfAudit: '',
      editInfo: '',
      exist: '',
      orgLogoList: [],
      isTopOps: isTopOps(),
      statusOps: statusOps(),
      searchOrgParams: {
        searchUrl: getOrgListURL,
        request: {
          text: 'orgName',
          value: 'id'
        },
        response: {
          text: 'orgName',
          value: 'id'
        }
      },
      uploadLogoOptions: {
        action: `${uploadFileURL}informationIcon`,
        multiple: false,
        fileSize: 5
      },
      searchManagerParams: {
        searchUrl: getOrgManagerURL,
        request: {
          text: 'keyword',
          value: 'id'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      }
    }
  },
  created () {
    if (this.isEdit) this.bindOrgData(this.data)
  },
  methods: {
    changeManage (data) {
      if (data) {
        this.form.managerName = data.username
      }
    },
    bindOrgData (d) {
      // 存储校验数据，用于组织校验
      this.orgCheckData.isTop = d.isTop
      this.orgCheckData.orgName = d.orgName
      this.orgCheckData.topOrgId = d.topParentId
      this.orgCheckData.supOrgId = d.parentId

      this.form.isTop = d.isTop
      this.form.orgName = d.orgName
      this.form.orgLogo = d.orgLogo
      if (d.orgLogo) {
        this.orgLogoList.push({
          url: d.orgLogo
        })
      } else {
        this.orgLogoList = []
      }
      this.form.topParentId = d.topParentId
      this.form.topParentName = d.topParentName
      this.form.parentId = d.parentId
      this.form.parentName = d.parentName
      this.form.managerId = d.managerId
      this.form.managerName = d.managerName
      this.form.status = d.status
      this.editInfo = d.editInfo
      this.editIfAudit = d.editIfAudit
      this.exist = d.exist
      if (d.tagsAttrs && d.tagsAttrs.length > 0) {
        d.tagsAttrs.forEach(item => {
          if (item.inputType === 2 || item.inputType === 3) {
            let temp = []
            item.attributeValue = item.attributeValue !== '' ? JSON.parse(item.attributeValue) : []
            if (item.attributeValue.length > 0) {
              if (item.inputType === 2) {
                item.attributeValue.map(k => {
                  temp.push({
                    url: k
                  })
                })
              } else {
                // 当标签属性为附件时提取文件名称
                item.attributeValue.map(k => {
                  temp.push({
                    url: k,
                    isOther: true,
                    name: k.split('/').pop()
                  })
                })
              }
            }
            item.attributeValue = temp
          }
          if (item.inputType === 4) {
            if (!item.attributeValue) {
              item.attributeValue = undefined
            }
            item.options = []
            this.queryOps(item)
          }
        })
      }
      this.form.tagsAtts = d.tagsAttrs
    },
    next () {
      // 下一步切换tab
      this.$emit('contact', {
        method: 'setActiveLabel',
        data: '组织简介'
      })

      // 把基础信息的数据带到下一步保存
      if (this.orgLogoList.length > 0) {
        this.form.orgLogo = this.orgLogoList[0].url
      }
      this.$nextTick(() => {
        this.$emit('contact', {
          index: 1,
          method: 'acceptBaseInfo',
          data: this.form
        })
      })
    },
    checkOrg () {
      let result = this.$refs.baseForm.validate()
      if (!result) return false
      let _this = this
      let isCheck = false
      let postData = {
        orgId: this.form.orgId,
        centerType: this.form.centerType,
        isTop: this.form.isTop,
        topOrgId: this.form.topParentId || 0,
        supOrgId: this.form.parentId || 0,
        orgName: this.form.orgName
      }
      // 判断是否有改过是否顶层组织、组织名称、顶层组织、上级组织数据，如果改过就校验组织
      for (let k in _this.orgCheckData) {
        if (_this.orgCheckData[k] !== postData[k]) {
          isCheck = true
          break
        }
      }
      if (isCheck) {
        _this.$axios({
          url: checkOrgURL,
          data: postData,
          method: 'post'
        }).then(res => {
          if (res.data && res.data.exist) {
            _this.$confirm(`${_this.form.orgName}已存在，你需要显示该组织的详情吗？`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then((status) => {
              if (status) {
                _this.bindOrgData(res.data)
                _this.$emit('contact', {
                  index: 1,
                  method: 'bindOrgData',
                  data: res.data
                })
                _this.next()
              }
            })
          } else {
            _this.next()
          }
        })
      } else {
        // 关闭组织时会有提示
        if (_this.form.status === 2) {
          _this.$confirm('该组织关闭后关联该组织的用户将解除其关联关系，是否继续？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            _this.next()
          })
        } else {
          _this.next()
        }
      }
    },
    async queryOps (item) {
      if (item.attributeSource) {
        let { data } = await this.$axios.get(getSelectOpsURL, {
          params: {
            typeId: item.attributeSource
          }
        })
        item.options = data.map(item => ({ text: item.name, value: item.id }))
        this.$set(item)
      }
    }
  }
}
</script>
