var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form-panel",
        {
          ref: "baseForm",
          attrs: { form: _vm.form },
          scopedSlots: _vm._u([
            {
              key: "footerSlot",
              fn: function () {
                return [
                  _c("v-button", {
                    attrs: { text: "下一步", type: "success" },
                    on: { click: _vm.checkOrg },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "col2-detail",
            [
              _c("col2-block", { attrs: { title: "基础信息" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "是否顶层组织",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择是否顶层组织",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.form.isTop === 1 ? "是" : "否")
                              ),
                            ]),
                          ]
                        ),
                        _vm.form.isTop === 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "顶层组织" } },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.form.topParentName)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "上级组织" } },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.form.parentName)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "组织名称",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入组织名称",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.form.orgName))])]
                        ),
                        _c("el-form-item", { attrs: { label: "组织logo" } }, [
                          _c("img", {
                            attrs: {
                              width: "140",
                              height: "140",
                              src: _vm.form.orgLogo,
                              alt: "",
                            },
                          }),
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "组织状态",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择组织状态",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.form.status === 1 ? "正常" : "关闭")
                              ),
                            ]),
                          ]
                        ),
                        _c("el-form-item", { attrs: { label: "组织超管" } }, [
                          _c("span", [_vm._v(_vm._s(_vm.form.managerName))]),
                        ]),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "是否顶层组织",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择是否顶层组织",
                                  trigger: "blur",
                                },
                              ],
                              prop: "isTop",
                            },
                          },
                          [
                            _c("v-select", {
                              attrs: {
                                options: _vm.isTopOps,
                                width: _vm.width,
                              },
                              model: {
                                value: _vm.form.isTop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isTop", $$v)
                                },
                                expression: "form.isTop",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.form.isTop === 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "顶层组织" } },
                                  [
                                    _c(
                                      "v-select2",
                                      _vm._b(
                                        {
                                          attrs: {
                                            placeholder: "查询顶层组织",
                                            width: _vm.width,
                                          },
                                          model: {
                                            value: _vm.form.topParentId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "topParentId",
                                                $$v
                                              )
                                            },
                                            expression: "form.topParentId",
                                          },
                                        },
                                        "v-select2",
                                        _vm.searchOrgParams,
                                        false
                                      )
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "上级组织" } },
                                  [
                                    _c(
                                      "v-select2",
                                      _vm._b(
                                        {
                                          attrs: {
                                            placeholder: "查询上级组织",
                                            width: _vm.width,
                                            subjoin: {
                                              topId: _vm.form.topParentId,
                                            },
                                          },
                                          model: {
                                            value: _vm.form.parentId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "parentId",
                                                $$v
                                              )
                                            },
                                            expression: "form.parentId",
                                          },
                                        },
                                        "v-select2",
                                        _vm.searchOrgParams,
                                        false
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "组织名称",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入组织名称",
                                  trigger: "blur",
                                },
                              ],
                              prop: "orgName",
                            },
                          },
                          [
                            _c("v-input", {
                              attrs: {
                                placeholder: "请输入组织名称",
                                width: _vm.width,
                              },
                              model: {
                                value: _vm.form.orgName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "orgName", $$v)
                                },
                                expression: "form.orgName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "组织logo" } },
                          [
                            _c(
                              "v-uploader",
                              _vm._b(
                                {
                                  attrs: { fileList: _vm.orgLogoList },
                                  on: {
                                    "update:fileList": function ($event) {
                                      _vm.orgLogoList = $event
                                    },
                                    "update:file-list": function ($event) {
                                      _vm.orgLogoList = $event
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "tip",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n                点击上传图片，图片最大不超过5M\n              "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                "v-uploader",
                                _vm.uploadLogoOptions,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "组织状态",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择组织状态",
                                  trigger: "blur",
                                },
                              ],
                              prop: "status",
                            },
                          },
                          [
                            _c("v-select", {
                              attrs: {
                                options: _vm.statusOps,
                                width: _vm.width,
                              },
                              model: {
                                value: _vm.form.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "组织超管" } },
                          [
                            _c(
                              "v-select2",
                              _vm._b(
                                {
                                  attrs: {
                                    placeholder: "查询组织超管",
                                    width: _vm.width,
                                  },
                                  on: { onChange: _vm.changeManage },
                                  model: {
                                    value: _vm.form.managerId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "managerId", $$v)
                                    },
                                    expression: "form.managerId",
                                  },
                                },
                                "v-select2",
                                _vm.searchManagerParams,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ]),
            ],
            1
          ),
          _vm.form.tagsAtts && _vm.form.tagsAtts.length
            ? _c(
                "col2-block",
                { attrs: { title: "补充信息" } },
                _vm._l(_vm.form.tagsAtts, function (item, index) {
                  return _c(
                    "div",
                    { key: `${item.id}&&${item.attributeName}` },
                    [
                      item.inputType === 1
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: item.attributeName,
                                    rules: [
                                      {
                                        required: item.required === 1,
                                        message: "请添加" + item.attributeName,
                                      },
                                    ],
                                    prop: `tagsAtts.${index}.attributeValue`,
                                  },
                                },
                                [
                                  _c("v-input", {
                                    attrs: {
                                      placeholder: `请输入${item.attributeName}`,
                                      width: _vm.width,
                                    },
                                    model: {
                                      value: item.attributeValue,
                                      callback: function ($$v) {
                                        _vm.$set(item, "attributeValue", $$v)
                                      },
                                      expression: "item.attributeValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      item.inputType === 2
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: item.attributeName,
                                    rules: [
                                      {
                                        required: item.required === 1,
                                        message: "请添加" + item.attributeName,
                                      },
                                    ],
                                    prop: `tagsAtts.${index}.attributeValue`,
                                  },
                                },
                                [
                                  _c(
                                    "v-uploader",
                                    _vm._b(
                                      {
                                        attrs: {
                                          fileList: item.attributeValue,
                                        },
                                        on: {
                                          "update:fileList": function ($event) {
                                            return _vm.$set(
                                              item,
                                              "attributeValue",
                                              $event
                                            )
                                          },
                                          "update:file-list": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              item,
                                              "attributeValue",
                                              $event
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "tip",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n                  单张图片的大小不能超过 5M\n                "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      "v-uploader",
                                      _vm.uploadImagesOptions,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      item.inputType === 3
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: item.attributeName,
                                    rules: [
                                      {
                                        required: item.required === 1,
                                        message: "请添加" + item.attributeName,
                                      },
                                    ],
                                    prop: `tagsAtts.${index}.attributeValue`,
                                  },
                                },
                                [
                                  _c(
                                    "v-uploader",
                                    _vm._b(
                                      {
                                        attrs: {
                                          fileList: item.attributeValue,
                                        },
                                        on: {
                                          "update:fileList": function ($event) {
                                            return _vm.$set(
                                              item,
                                              "attributeValue",
                                              $event
                                            )
                                          },
                                          "update:file-list": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              item,
                                              "attributeValue",
                                              $event
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "tip",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n                  单个文件的大小不能超过 10M\n                "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      "v-uploader",
                                      _vm.uploadFilesOptions,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      item.inputType === 4
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: item.attributeName,
                                    rules: [
                                      {
                                        required: item.required === 1,
                                        message: "请添加" + item.attributeName,
                                      },
                                    ],
                                    prop: `tagsAtts.${index}.attributeValue`,
                                  },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      placeholder: `请输入${item.attributeName}`,
                                      options: item.options,
                                      width: _vm.width,
                                    },
                                    model: {
                                      value: item.attributeValue,
                                      callback: function ($$v) {
                                        _vm.$set(item, "attributeValue", $$v)
                                      },
                                      expression: "item.attributeValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }